<template>
  <v-card>
    <v-card-title>{{ formatTitleDate(item.paidAt) }}, {{ formatPrice(item.amount) }} &#x20bd;</v-card-title>
    <v-card-text>
      <pre>Комплект:<span> {{ item.kit.serialNumber }}</span></pre>
      <pre>Смена<span> {{ item.shiftNumber }}</span
                  >, чек<span> {{ item.shiftReceiptNumber }}</span></pre>
      <pre>Проводник:<span> {{ item.conductor }}</span></pre>

      <template v-if="barcodes.length || barcodesLg.length">
        <div class="mt-2 d-flex flex-column">
          <span class="font-weight-medium">Штрих-коды</span>
          <span class="caption">ШК: {{ barcodes.join(', ') }}</span>
          <span v-if="barcodesLg.length" class="caption">ШК льготные: {{ barcodesLg.join(', ') }}</span>
        </div>
      </template>

      <template v-if="item.paymentDetails.length > 0">
        <div class="mt-2" :set="(payment = item.paymentDetails[0])">
          <span class="font-weight-medium">Информация об оплате</span>
          <pre>Терминал:<span> {{ payment.terminalId }}</span></pre>
          <pre>Дата/время (хост):<span> {{ formatDate(payment.terminalReceiptDate) }}</span></pre>
          <pre>Номер банковского чека:<span> {{ payment.terminalReceiptNumber }}</span></pre>
          <pre>Карта:<span> {{ payment.cardType }} {{payment.cardNumber}}</span></pre>
        </div>

        <template v-if="payment.payType === 13">
          <div class="mt-2" :set="(payment = item.paymentDetails[0])">
            <span class="font-weight-medium">Отложенный платеж</span>
            <pre>Платеж<span> {{ getDelayedPayStatus(payment) }}</span></pre>
            <pre>Статус получен<span> {{ formatDate(payment.updatedAt) }}</span></pre>
            <pre>Ответ банка:<span> {{ payment.delayedPayResultDescription }}</span></pre>
            <pre>Код ответа банка:<span> {{ payment.delayedPayResultCode }}</span></pre>
          </div>
        </template>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    barcodes() {
      if (!this.item.orderDetails.barcodes) return []
      return this.item.orderDetails.barcodes.map((b) => b.barcode)
    },
    barcodesLg() {
      if (!this.item.orderDetails.barcodesLg) return []
      return this.item.orderDetails.barcodesLg.map((b) => b.barcode)
    }
  },
  methods: {
    formatTitleDate(date) {
      return this.$moment(date).format('DD MMM YYYY в HH:mm')
    },
    formatDate(date) {
      return this.$moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    formatPrice(price) {
      const parts = Number(price).toFixed(2).toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      if (parts[1] && parts[1] === '00') return parts[0]
      return parts.join('.')
    },
    getDelayedPayStatus(paymentDetails) {
      if (paymentDetails.delayedPayResult === 0) return 'в обработке'
      if (paymentDetails.delayedPayResult === 1) return 'исполнен ' + this.formatDate(paymentDetails.delayedPayDate)
      if (paymentDetails.delayedPayResult === 2) return 'отклонен ' + this.formatDate(paymentDetails.delayedPayDate)
      return paymentDetails.delayedPayResult
    }
  }
}
</script>
