<template>
  <v-data-table
    :items="items"
    :headers="headers"
    :loading="loading"
    :server-items-length="items.length"
    hide-default-footer
    loading-text="Загрузка..."
    no-data-text="Нет записей"
    no-results-text="Записи не найдены"
  >
    <template v-slot:item="{ item }">
      <tr class="receipt" :key="`receipt-${item.id}`" :set="(order = item.orderDetails)">
        <td :class="itemRowClass(order.receiptType)">
          <v-dialog width="450">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text out class="body-2 text-none" v-bind="attrs" v-on="on">
                {{ formatReceiptHeader(item.paidAt, item.amount) }}
                <v-icon v-if="hasBarcodes(item.orderDetails)" color="grey darken-2" class="ml-2">mdi-barcode</v-icon>
              </v-btn>
            </template>
            <SaleDetailsCard :item="item" />
          </v-dialog>
          <v-chip v-if="order.receiptType === 2" class="ml-2" label small outlined color="warning darken-1"> возврат </v-chip>
          <v-chip v-if="order.receiptType !== 2 && item.paymentDetails && isDelayedPay(item.paymentDetails)" class="ml-2" label small outlined>
            <span class="mr-1">постоплата</span>
            <v-icon small>{{ getDelayedPayStatusIconName(item.paymentDetails) }}</v-icon>
          </v-chip>
        </td>
        <td>{{ item.kit.serialNumber }}</td>
        <td>{{ getLocationDescription(item.kitLocation) }}</td>
        <td :colspan="3">{{ getPaymentDescription(order.paymentType, order.receiptType) }}</td>
      </tr>
      <tr v-for="(soldItem, soldItemIndex) in item.orderDetails.items" :key="`receipt-${item.id}-item-${soldItemIndex}`">
        <td>
          {{ `${soldItem.title} (арт.№${soldItem.article}) (${soldItem.quantity}шт)` }}
        </td>
        <td colspan="3" />
        <td>{{ `${parseFloat(soldItem.totalSum - soldItem.totalTax).toFixed(2)}` }}р</td>
        <td>{{ soldItem.totalSum }}р</td>
      </tr>
    </template>
    <template v-slot:group.summary> </template>
  </v-data-table>
</template>

<script>
import SaleDetailsCard from './SaleDetailsCard'
export default {
  props: {
    items: Array,
    loading: Boolean
  },
  data() {
    return {
      headers: [
        {
          text: 'Товар/услуга (артикул) (кол-во)',
          value: 'title',
          sortable: false
        },
        {
          text: 'Комплект',
          value: 'kitSerialNumber',
          sortable: false
        },
        {
          text: 'Место продажи',
          value: 'kitLocation',
          sortable: false
        },
        {
          text: 'Вид расчета',
          value: 'payment',
          sortable: false
        },
        { text: 'Цена без НДС', value: 'total', sortable: false },
        { text: 'Цена с НДС', value: 'totalWithTax', sortable: false }
      ]
    }
  },
  components: {
    SaleDetailsCard
  },
  methods: {
    formatReceiptHeader(date, amount) {
      const today = this.$moment()
      const dayFormat = 'YYYY-MM-DD'
      const targetDate = this.$moment(date)
      let format = `${dayFormat} в HH:mm`
      if (targetDate.format(dayFormat) === today.format(dayFormat)) {
        format = `Сегодня в HH:mm`
      } else if (targetDate.format(dayFormat) === today.subtract(1, 'days').format(dayFormat)) {
        format = `Вчера в HH:mm`
      }
      return targetDate.format(format) + ', ' + amount + 'р'
    },
    getLocationDescription(location) {
      let description = 'Неизвестно'
      if (location === 1) {
        description = 'Ретропоезд'
      } else if (location === 2) {
        description = 'Беспересадочный'
      } else if (location === 3) {
        description = 'Ленинградский вокзал'
      } else if (location === 4) {
        description = '019 28316'
      } else if (location === 5) {
        description = '086 15965'
      } else if (location === 6) {
        description = '086 15916'
      } else if (location === 7) {
        description = '084 11829'
      }
      return description
    },
    getPaymentDescription(paymentType, receiptType) {
      let paymentDescription = 'Неизвестно'
      if (receiptType === 68) {
        paymentDescription = 'Предварительный заказ'
      } else if (paymentType === 2) {
        paymentDescription = 'Безналичный'
      }
      return paymentDescription
    },
    itemRowClass(receiptType) {
      return receiptType === 2 ? 'item-refund' : receiptType !== 68 ? 'item-income' : 'item-preorder'
    },
    isDelayedPay(paymentDetails) {
      return paymentDetails.length > 0 && paymentDetails[0].payType === 13
    },
    hasBarcodes(orderDetails) {
      return (orderDetails.barcodes && orderDetails.barcodes.length > 0) || (orderDetails.barcodesLg && orderDetails.barcodesLg.length > 0)
    },
    getDelayedPayStatusIconName(paymentDetails) {
      if (paymentDetails[0].delayedPayResult === 1) return 'mdi-progress-check'
      else if (paymentDetails[0].delayedPayResult === 2) return 'mdi-progress-close'
      return 'mdi-progress-clock'
    }
  }
}
</script>
<style scoped>
.receipt {
  background-color: rgba(0, 0, 0, 0.05);
}
.item-preorder {
  border-left: 3px solid lightgray;
}
.item-refund {
  border-left: 3px solid #fb8c00;
}
.item-income {
  border-left: 3px solid #00e676;
}
.item-failure {
  border-left: 3px solid #ff8a80;
}
</style>
