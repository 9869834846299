<template>
  <div class="projects-sales">
    <h1 class="subtitle-1 grey--text">Данные о продажах</h1>
    <v-row class="mx-0 mt-2 grey lighten-3">
      <v-col md="4" class="d-flex">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text @click="downloadReport" v-bind="attrs" v-on="on">
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
          </template>
          <span>Выгрузить в Excel</span>
        </v-tooltip>
        <v-menu
          ref="dateRangePicker"
          v-model="isDateRangePickerVisible"
          :close-on-content-click="false"
          :return-value.sync="dateRange"
          transition="slide-y-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              class="ml-3"
              prepend-inner-icon="mdi-calendar-range"
              hide-details
              readonly
              flat
              solo
              dense
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker v-model="datePickerRange" :first-day-of-week="1" :max="`${currentDate}`" @change="onDatePickerRangeChange" no-title range scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="isDateRangePickerVisible = false">Отмена</v-btn>
            <v-btn text color="primary" @click="onDatePickerRangeSave">OK</v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="8" class="pt-0 pt-md-3">
        <v-text-field
          v-model="search"
          placeholder="Наименование, комплект, артикул или штрих-код"
          prepend-inner-icon="mdi-magnify"
          hide-details
          single-line
          clearable
          dense
          flat
          solo
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-chip-group v-model="receiptTypeSelect" multiple column>
          <div class="d-flex flex-column">
            <v-chip label outlined class="mr-0">
              <v-icon x-small left color="success"> mdi-circle </v-icon>
              Продажи
              <template v-if="receiptTypeSelect.includes(0)">
                <span class="ml-2">{{ formatPrice(incomeAmount) }}</span>
                <span class="ml-1 grey--text">&#x20bd;</span>
              </template>
            </v-chip>
            <v-chip-group v-if="receiptTypeSelect.includes(0)" v-model="delayedPayStatusSelect" multiple>
              <v-chip small label outlined>
                <v-icon small left class="grey--text text--darken-1">mdi-check-circle-outline</v-icon>
                <span>{{ formatPrice(regularPayIncomeAmount) }}</span>
              </v-chip>
              <v-chip small label outlined>
                <v-icon small left class="grey--text text--darken-1">mdi-progress-check</v-icon>
                <span>{{ formatPrice(confirmedDelayedPayIncomeAmount) }}</span>
              </v-chip>
              <v-chip small label outlined>
                <v-icon small left class="grey--text text--darken-1">mdi-progress-clock</v-icon>
                <span>{{ formatPrice(processingDelayedPayIncomeAmount) }}</span>
              </v-chip>
              <v-chip small label outlined class="mr-0">
                <v-icon small left class="grey--text text--darken-1">mdi-progress-close</v-icon>
                <span>{{ formatPrice(failureDelayedPayIncomeAmount) }}</span>
              </v-chip>
            </v-chip-group>
          </div>
          <v-chip label outlined class="ml-md-2">
            <v-icon x-small left color="warning">mdi-circle </v-icon>
            Возвраты
            <template v-if="receiptTypeSelect.includes(1)">
              <span class="ml-2 mr-1">{{ formatPrice(refundAmount) }}</span>
              <span class="grey--text">&#x20bd;</span>
            </template>
          </v-chip>
          <v-chip label outlined>
            <v-icon x-small left color="grey"> mdi-circle </v-icon>
            Предзаказы
            <template v-if="receiptTypeSelect.includes(2)">
              <span class="ml-2 mr-1">{{ formatPrice(preOrderAmount) }}</span>
              <span class="grey--text">&#x20bd;</span>
            </template>
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
    <v-divider />
    <SaleList :items="filteredReceipts" />
  </div>
</template>

<script>
import SaleService from '@/services/SaleService'
import SaleList from '@/components/sale/SaleList'
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    const today = this.$moment().format('YYYY-MM-DD')
    return {
      isDateRangePickerVisible: false,
      datePickerRange: [today, today],
      dateRange: [today, today],
      receiptTypeSelect: [0, 1],
      delayedPayStatusSelect: [0, 1, 2, 3],
      currentDate: today,
      search: null
    }
  },
  components: {
    SaleList
  },
  computed: {
    projectId() {
      return this.$route.params.projectId
    },
    queryParams() {
      const params = new URLSearchParams()
      params.append('start', this.datePickerRange[0])
      params.append('end', this.datePickerRange[1])
      return params
    },
    typeFilteredReceipts() {
      if (this.receiptTypeSelect.length === 0) return []
      return this.sales.filter(
        (receipt) =>
          this.includeRegularPayIncomeReceipt(receipt) ||
          this.includeConfirmedDelayedPayIncomeReceipt(receipt) ||
          this.includeProcessingDelayedPayIncomeReceipt(receipt) ||
          this.includeFailureDelayedPayIncomeReceipt(receipt) ||
          this.includeRefundReceipt(receipt) ||
          this.includePreOrderReceipt(receipt)
      )
    },
    filteredReceipts() {
      return this.typeFilteredReceipts.filter(
        (s) =>
          (this.search == null ||
            s.kit.serialNumber.includes(this.search) ||
            s.orderDetails.items.some((it) => it.title.includes(this.search) || it.article.includes(this.search)) ||
            s.orderDetails.barcodes.some((it) => it.barcode.includes(this.search))) &&
          s.orderDetails.receiptType
      )
    },
    incomeAmount() {
      return this.filteredReceipts.filter((receipt) => receipt.orderDetails.receiptType === 1).reduce((sum, receipt) => sum + Number(receipt.amount), 0)
    },
    refundAmount() {
      return this.filteredReceipts.filter((receipt) => receipt.orderDetails.receiptType === 2).reduce((sum, cur) => sum + Number(cur.amount), 0)
    },
    preOrderAmount() {
      return this.filteredReceipts.filter((receipt) => receipt.orderDetails.receiptType === 68).reduce((sum, cur) => sum + Number(cur.amount), 0)
    },
    regularPayIncomeAmount() {
      return this.filteredReceipts
        .filter((receipt) => receipt.orderDetails.receiptType === 1 && !this.isDelayedPay(receipt.paymentDetails))
        .reduce((sum, receipt) => sum + Number(receipt.amount), 0)
    },
    confirmedDelayedPayIncomeAmount() {
      return this.filteredReceipts
        .filter((receipt) => receipt.orderDetails.receiptType !== 2 && this.isDelayedIncome(receipt.paymentDetails))
        .reduce((sum, receipt) => sum + Number(receipt.amount), 0)
    },
    processingDelayedPayIncomeAmount() {
      return this.filteredReceipts
        .filter((receipt) => receipt.orderDetails.receiptType !== 2 && this.isDelayedProcess(receipt.paymentDetails))
        .reduce((sum, receipt) => sum + Number(receipt.amount), 0)
    },
    failureDelayedPayIncomeAmount() {
      return this.filteredReceipts
        .filter((receipt) => receipt.orderDetails.receiptType !== 2 && this.isDelayedError(receipt.paymentDetails))
        .reduce((sum, receipt) => sum + Number(receipt.amount), 0)
    },
    dateRangeText() {
      const dateFrom = this.$moment(this.datePickerRange[0])
      const dateTo = this.$moment(this.datePickerRange[1])
      return this.formatDateRangeText(dateFrom, dateTo)
    },
    ...mapGetters('project', ['sales'])
  },
  mounted() {
    this.fetchReceipts()
  },
  methods: {
    fetchReceipts() {
      this.fetchSalesByDate({
        projectId: this.projectId,
        queryParams: this.queryParams
      })
    },
    onDatePickerRangeChange() {
      if (this.datePickerRange[1] < this.datePickerRange[0]) {
        this.datePickerRange.reverse()
      }
    },
    onDatePickerRangeSave() {
      if (this.datePickerRange.length === 1) {
        this.datePickerRange.push(this.currentDate)
      }
      this.$refs.dateRangePicker.save(this.datePickerRange)
      this.fetchReceipts()
    },
    formatDateRangeText(dateFrom, dateTo) {
      if (dateTo.diff(dateFrom) === 0) {
        return `${dateFrom.format('D MMM')}`
      }
      let dateFromFormat, dateToFormat
      dateFromFormat = dateToFormat = 'D MMM YYYY'
      if (dateFrom.year() === dateTo.year()) {
        if (dateFrom.year() === this.$moment(this.currentDate).year()) {
          dateToFormat = 'D MMM'
        }
        if (dateFrom.month() === dateTo.month()) {
          dateFromFormat = 'D'
        } else {
          dateFromFormat = 'D MMM'
        }
      }

      return `С ${dateFrom.format(dateFromFormat)} по ${dateTo.format(dateToFormat)}`
    },
    formatPrice(price) {
      const parts = Number(price).toFixed(2).toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      if (parts[1] && parts[1] === '00') return parts[0]
      return parts.join('.')
    },
    downloadReport() {
      SaleService.queryReport(this.projectId, this.queryParams).then((res) => {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([res.data]))
        link.setAttribute('download', `Данные о продажах.xlsx`)
        document.body.appendChild(link)
        link.click()
      })
    },
    includeIncomeReceipt(receipt) {
      return this.receiptTypeSelect.includes(0) && receipt.orderDetails.receiptType === 1
    },
    includeRegularPayIncomeReceipt(receipt) {
      return this.includeIncomeReceipt(receipt) && this.delayedPayStatusSelect.includes(0) && !this.isDelayedPay(receipt.paymentDetails)
    },
    includeConfirmedDelayedPayIncomeReceipt(receipt) {
      return this.includeIncomeReceipt(receipt) && this.delayedPayStatusSelect.includes(1) && this.isDelayedIncome(receipt.paymentDetails)
    },
    includeProcessingDelayedPayIncomeReceipt(receipt) {
      return this.includeIncomeReceipt(receipt) && this.delayedPayStatusSelect.includes(2) && this.isDelayedProcess(receipt.paymentDetails)
    },
    includeFailureDelayedPayIncomeReceipt(receipt) {
      return this.includeIncomeReceipt(receipt) && this.delayedPayStatusSelect.includes(3) && this.isDelayedError(receipt.paymentDetails)
    },
    includeRefundReceipt(receipt) {
      return this.receiptTypeSelect.includes(1) && receipt.orderDetails.receiptType === 2
    },
    includePreOrderReceipt(receipt) {
      return this.receiptTypeSelect.includes(2) && receipt.orderDetails.receiptType === 68
    },
    isDelayedPay(paymentDetails) {
      return paymentDetails && paymentDetails.length > 0 && paymentDetails[0].payType === 13
    },
    isDelayedIncome(paymentDetails) {
      return this.isDelayedPay(paymentDetails) && paymentDetails[0].delayedPayResult === 1
    },
    isDelayedProcess(paymentDetails) {
      return this.isDelayedPay(paymentDetails) && paymentDetails[0].delayedPayResult === 0
    },
    isDelayedError(paymentDetails) {
      return this.isDelayedPay(paymentDetails) && paymentDetails[0].delayedPayResult === 2
    },
    ...mapActions('project', ['fetchSalesByDate'])
  }
}
</script>
